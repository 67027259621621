const getCookie = (cname: string) => {
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  var cookieValue = '';
  ca.forEach((cookie: string) => {
    if (cookie.split('=')[0].trim() === cname) {
      cookieValue = cookie.split('=')[1];
    }
  });
  return cookieValue;
};

export default getCookie;
